import { createSlice } from '@reduxjs/toolkit';
import { loadSearchedOrders, loadSearchedOrdersAuth } from './actions';

const initialState = {
  searchedOrders: null,
  error: null,
  loading: false,
};

export const searchedOrdersSlice = createSlice({
  name: 'searchedOrders',
  initialState,
  reducers: {
    setSearchedOrdersError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadSearchedOrders.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loadSearchedOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(loadSearchedOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.searchedOrders = action.payload;
      })

      .addCase(loadSearchedOrdersAuth.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loadSearchedOrdersAuth.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(loadSearchedOrdersAuth.fulfilled, (state, action) => {
        state.loading = false;
        state.searchedOrders = action.payload;
      });
  },
});

export const { setSearchedOrdersError } = searchedOrdersSlice.actions;

export const selectSearchedOrders = (state) =>
  state.searchedOrders.searchedOrders;
export const selectOrdersLoading = (state) => state.searchedOrders.loading;
export const selectSearchedOrdersError = (state) => state.searchedOrders.error;

export const reducer = searchedOrdersSlice.reducer;
export default reducer;
