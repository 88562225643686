import { useNavigate } from 'react-router-dom';

import styles from './MessageCard.module.css';

import BoxIcon from '../../icons/BoxIcon';
import CardIcon from '../../icons/CardIcon';
import PhotocamIcon from '../../icons/PhotocamIcon';
import ArrowForwardIcon from '../../icons/ArrowForwardIcon';

import { MESSAGES, NEGOTIATION } from '../../utils/constants';

function MessageCard({ item }) {
  const navigate = useNavigate();

  const handleUserShow = () => {
    navigate(
      `${MESSAGES}/${item.id_order}${NEGOTIATION}/${item.id_negotiation}`
    );
  };

  return (
    <div className={styles.message_card}>
      <button
        type="button"
        onClick={handleUserShow}
        className={styles.message_card__button}
      >
        <div className={styles.message_card__main_container}>
          <p
            className={styles.message_card__price}
          >{`${item.original_price} €`}</p>

          <>
            <BoxIcon currentColor={'var(--green)'} />
            <ArrowForwardIcon currentColor={'var(--green)'} />
            <PhotocamIcon currentColor={'var(--dark-grey)'} />
            <ArrowForwardIcon currentColor={'var(--dark-grey)'} />
            <CardIcon currentColor={'var(--dark-grey)'} />
          </>

          <p className={styles.message_card__timeinfo}>
            {new Date(item.last_message_timestamp).toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </p>
        </div>

        <div className={styles.message_card__picture_description_container}>
          <div className={styles.message_card__picture_container}></div>
          <div className={styles.message_card__text_container}>
            <p className={styles.message_fromto}>From</p>
            <p className={styles.message_fromto}>To</p>
          </div>

          <div className={styles.message_card__routh_container}>
            <p className={styles.message_card__description}>
              {item.short_description}
            </p>
            <div className={styles.message_card__from_town_date_container}>
              <p className={styles.message_card__town}>{item.from_town}</p>
              <div className={styles.message_card__date_container}>
                <p className={styles.message_card__date}>
                  {new Date(item.from_datetime).toLocaleDateString()}
                </p>
                <p className={styles.message_card__time}>
                  {new Date(item.from_datetime).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </p>
              </div>
            </div>

            <div className={styles.message_card__to_town_date_container}>
              <p className={styles.message_card__town}>{item.to_town}</p>
              <div className={styles.message_card__date_container}>
                <p className={styles.message_card__date}>
                  {new Date(item.to_datetime).toLocaleDateString()}
                </p>
                <p className={styles.message_card__time}>
                  {new Date(item.to_datetime).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </p>
              </div>
            </div>
          </div>
        </div>

        {item.isOwner ? (
          <div className={styles.message_container}>
            <p className={styles.message_last__message}>{item.proposed_from}</p>
            <p className={styles.message_last__message}>
              {item.last_offer_message}
            </p>
            <p className={styles.message_author}>{item.proposed_to}</p>
            <p className={styles.message_last__message}>
              {item.last_price_offered}
            </p>
          </div>
        ) : (
          <div className={styles.message_container}>
            <p className={styles.message_author}>{item.proposed_from}</p>
            <p className={styles.message_last__message}>
              {item.last_offer_message}
            </p>
            <p className={styles.message_last__message}>
              {item.last_price_offered}
            </p>
          </div>
        )}
      </button>
    </div>
  );
}

export default MessageCard;
