const SearchIcon = ({ currentColor }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6114 19.5354L17.8845 15.8109C17.8176 15.744 17.7266 15.7063 17.6318 15.7063C17.6318 15.7063 17.6318 15.7063 17.6315 15.7063C17.537 15.7063 17.446 15.744 17.3788 15.8109L16.7673 16.4229L15.6672 15.323C17.0093 13.9116 17.8355 12.0057 17.8355 9.90888C17.8355 5.57216 14.3072 2.04395 9.97053 2.04395C5.63405 2.04371 2.10583 5.57192 2.10583 9.90864C2.10583 14.2456 5.63405 17.7736 9.97077 17.7736C11.9542 17.7736 13.7672 17.0339 15.1522 15.8183L16.2625 16.9284L15.7504 17.4408C15.611 17.5804 15.611 17.8067 15.7506 17.946L19.4772 21.6705C19.5442 21.7375 19.6352 21.7751 19.73 21.7751C19.8248 21.7751 19.9156 21.7375 19.9827 21.6705L21.6117 20.0407C21.6786 19.9737 21.7162 19.8827 21.7162 19.7879C21.716 19.6931 21.6784 19.6024 21.6114 19.5354ZM2.82048 9.90864C2.82048 5.96593 6.02806 2.75835 9.97077 2.75835C13.9135 2.75835 17.1211 5.96593 17.1211 9.90864C17.1211 13.8513 13.9135 17.0589 9.97077 17.0589C6.02806 17.0589 2.82048 13.8513 2.82048 9.90864ZM19.7295 20.9125L16.5086 17.6931L17.6323 16.5687L20.8534 19.7882L19.7295 20.9125Z"
        fill={currentColor}
        stroke={currentColor}
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default SearchIcon;
