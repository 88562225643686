import { Link, useLocation, useNavigate } from 'react-router-dom';
import styles from './header.module.css';
import {
  START,
  PROFILE,
  LOGIN,
  SEARCH_ORDERS,
  CURRENT_ORDERS,
} from '../../utils/constants';
import user_icon from '../../images/user_icon.svg';
import logo from '../../images/sd_logo.svg';
import { useSelector } from 'react-redux';
import { selectUser } from '../../services/user/reducer';

function Header() {
  const navigate = useNavigate();
  // const location = useLocation();

  const userToShow = useSelector(selectUser);
  const role = userToShow?.role;

  // const currentUrl = location.pathname.split('/').pop();

  let path;

  if (role === 'courier') {
    // Если роль "courier", перенаправляем на /search-orders
    path = SEARCH_ORDERS;
  } else if (role === 'sender') {
    // Если роль "sender", перенаправляем на /current-orders
    path = CURRENT_ORDERS;
  } else {
    // Если роль отсутствует, перенаправляем на стартовую страницу (например, SEARCH_COMMON)
    path = START; // Или любая другая стартовая страница
  }

  const handleLogoClick = () => {
    navigate(path);
  };

  const userPath = role ? PROFILE : LOGIN;

  return (
    <header className={styles.header}>
      <div className={styles.header__icon}></div>
      <button
        type="button"
        className={styles.header__logo_button}
        onClick={handleLogoClick}
      >
        <img src={logo} className={styles.header__logo} alt="logo" />
      </button>
      <Link to={userPath} className={styles.profile__menu_link}>
        <img src={user_icon} className={styles.header__icon} alt="user_icon" />
      </Link>
    </header>
  );
}

export default Header;
