import { useNavigate } from 'react-router-dom';

import styles from './DetailedMessage.module.css';

import ArrowBackIcon from '../../icons/ArrowBackIcon';

function DetailedMessage({ messageToShow }: any) {
  const navigate = useNavigate();

  console.log(messageToShow);

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <div>
      {messageToShow ? (
        <div className={styles.detailed_message}>
          <div className={styles.detailed_message__main_container}>
            <p
              className={styles.detailed_message__price}
            >{`${messageToShow.price} €`}</p>
            <button
              type="button"
              className={styles.detailed_message__button}
              onClick={handleBackClick}
            >
              <ArrowBackIcon currentColor={'var(--dark-grey)'} />
            </button>
            <p className={styles.detailed_message__link}>Full description</p>
          </div>

          <div
            className={styles.detailed_message__picture_description_container}
          >
            <div className={styles.detailed_message__picture_container}></div>
            <div className={styles.detailed_message__text_container}>
              <p className={styles.message_fromto}>From</p>
              <p className={styles.message_fromto}>To</p>
            </div>

            <div className={styles.detailed_message__routh_container}>
              <p className={styles.detailed_message__description}>
                {messageToShow.short_description}
              </p>
              <div
                className={styles.detailed_message__from_town_date_container}
              >
                <p className={styles.detailed_message__town}>
                  {messageToShow.from_town}
                </p>

                <div className={styles.detailed_message__date_container}>
                  <p className={styles.detailed_message__date}>
                    {new Date(messageToShow.from_datetime).toLocaleDateString()}
                  </p>
                  <p className={styles.detailed_message__time}>
                    {new Date(messageToShow.from_datetime).toLocaleTimeString(
                      [],
                      {
                        hour: '2-digit',
                        minute: '2-digit',
                      }
                    )}
                  </p>
                </div>
              </div>

              <div className={styles.detailed_message__to_town_date_container}>
                <p className={styles.detailed_message__town}>
                  {messageToShow.to_town}
                </p>

                <div className={styles.detailed_message__date_container}>
                  <p className={styles.detailed_message__date}>
                    {new Date(messageToShow.to_datetime).toLocaleDateString()}
                  </p>
                  <p className={styles.detailed_message__time}>
                    {new Date(messageToShow.to_datetime).toLocaleTimeString(
                      [],
                      {
                        hour: '2-digit',
                        minute: '2-digit',
                      }
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default DetailedMessage;
