import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styles from './Note.module.css';
import CommonButton from '../ui/common-button/common-button';
import { MESSAGES } from '../../utils/constants';
import { setTakeOrderNote } from '../../services/take-order/reducer';
import { setCreateOrderNote } from '../../services/create-order/reducer';

function Note({ noteText }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleOnClick() {
    dispatch(setCreateOrderNote(null));
    dispatch(setTakeOrderNote(null));
    navigate(MESSAGES, { replace: true });
  }

  return (
    <div className={styles.price_offer}>
      <p className={styles.price_offer__title}>{noteText}</p>
      <CommonButton
        type={'blue'}
        title={'Check your current negotiations'}
        onClick={handleOnClick}
      ></CommonButton>
    </div>
  );
}

export default Note;
