import { Link, useNavigate } from 'react-router-dom';
import styles from './start.module.css';
import CardIcon from '../../icons/CardIcon';
import RouteIcon from '../../icons/RouteIcon';
import CarIcon from '../../icons/CarIcon';
import FormSearchOrders from '../../components/form-search-orders/form-search-orders';
import BoxIcon from '../../icons/BoxIcon';
import ArrowThinIcon from '../../icons/ArrowThinIcon';
import PeopleIcon from '../../icons/PeopleIcon';
import CurrentOrderIcon from '../../icons/CurrentOrderIcon';
import HandshakeIcon from '../../icons/HandshakeIcon';
import MessagesIcon from '../../icons/MessagesIcon';
import CommonButton from '../../components/ui/common-button/common-button';
import facebook_icon from '../../images/facebook_icon.svg';
import instagram_icon from '../../images/instagram_icon.svg';
import linkedin_icon from '../../images/linkedin_icon.svg';
import email_icon from '../../images/email_icon.svg';
import whatsapp_icon from '../../images/whatsapp_icon.svg';
import { SIGNUP } from '../../utils/constants';

function Start() {
  const navigate = useNavigate();

  const currentColorBlue = 'var(--light-blue)';
  const currentColorWhite = 'var(--white)';

  const Sender = [
    'Register',
    "Create your delivery order and set your price or accept the courier's offer",
    'Courier picks up your cargo',
    'Receive a proof of delivery (photo) and confirm payment',
  ];
  const Courier = [
    'Register',
    'Enter your trip details to find a delivery match',
    'Agree on a price or suggest your own',
    'Pick up the cargo and start the delivery',
    'Upload a cargo photo to complete the delivery',
    "Once confirmed, you'll get paid",
  ];

  const handleClick = () => {
    navigate(SIGNUP);
  };

  return (
    <div className={styles.start}>
      <div className={styles.start__first_container}>
        <div className={styles.start__first_img}>
          <p className={styles.start__img_text}>
            ON THE WAY <br /> DELIVERY SERVICE
          </p>
        </div>
      </div>
      <div className={styles.start__courier_container}>
        <h3 className={styles.start__courier_title}>COURIER</h3>
        <div className={styles.start__icons_text_container}>
          <div className={styles.start__icons_container}>
            <CardIcon currentColor={currentColorBlue} />
            <RouteIcon currentColor={currentColorBlue} />
            <CarIcon currentColor={currentColorBlue} />
          </div>
          <div className={styles.start__texts_container}>
            <p className={styles.start__icon_text}>Save on your trips</p>
            <p className={styles.start__icon_text}>
              Deliver cargo on your route
            </p>
            <p className={styles.start__icon_text}>
              You don't have to work as a courier
            </p>
          </div>
        </div>
        <FormSearchOrders />
      </div>
      <div className={styles.start__mission_container}>
        <div className={styles.start__mission_img}>
          <div className={styles.start__mission_back}>
            <div className={styles.start__mission}>
              <div className={styles.start__mission_icons_title_container}>
                <div className={styles.start__mission_icons_container}>
                  <BoxIcon currentColor={currentColorWhite} />
                  <ArrowThinIcon currentColor={currentColorWhite} />
                  <CarIcon currentColor={currentColorWhite} />
                  <ArrowThinIcon currentColor={currentColorWhite} />
                  <PeopleIcon currentColor={currentColorWhite} />
                  <ArrowThinIcon currentColor={currentColorWhite} />
                  <CardIcon currentColor={currentColorWhite} />
                </div>
                <h3 className={styles.start__mission_title}>MISSION</h3>
              </div>
              <p className={styles.start__mission_text}>
                Making delivery simple, affordable
                <br /> and environmentally friendly
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.start__work_container}>
        <h3 className={styles.start__work_title}>HOW WE WORK</h3>
        <div className={styles.start__types_container}>
          <div className={styles.start__type_container}>
            <h4 className={styles.start__work_type}>Sender/Receiver</h4>
            {Sender.map((i, index) => (
              <div className={styles.start__work_item} key={index}>
                <p className={styles.start__work_title}>{index + 1}</p>
                <p className={styles.start__work_point}>{i}</p>
              </div>
            ))}
          </div>
          <div className={styles.start__type_container}>
            <h4 className={styles.start__work_type}>Courier</h4>
            {Courier.map((i, index) => (
              <div className={styles.start__work_item} key={index}>
                <p className={styles.start__work_title}>{index + 1}</p>
                <p className={styles.start__work_point}>{i}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.start__sender_container}>
        <div className={styles.start__sender_img}>
          <div className={styles.start__sender_back}>
            <div className={styles.start__sender}>
              <h3 className={styles.start__sender_title}>SENDER/RECEIVER</h3>
              <div className={styles.start__sender_icon_text_container}>
                <div className={styles.start__sender_icon}>
                  <CurrentOrderIcon currentColor={currentColorWhite} />
                </div>
                <p className={styles.start__sender_text}>
                  Deliver faster and cheaper than transit companies and the post
                  office
                </p>
              </div>
              <div className={styles.start__sender_icon_text_container}>
                <div className={styles.start__sender_icon}>
                  <HandshakeIcon currentColor={currentColorWhite} />
                </div>
                <p className={styles.start__sender_text}>
                  Set your own delivery price
                </p>
              </div>
              <div className={styles.start__sender_icon_text_container}>
                <div className={styles.start__sender_icon}>
                  <MessagesIcon currentColor={currentColorWhite} />
                </div>
                <p className={styles.start__sender_text}>
                  Stay in touch with the courier at any time
                </p>
              </div>
              <CommonButton
                type={'green'}
                title={'Register and create order'}
                disabled={false}
                onClick={handleClick}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.start__contacts}>
        <div className={styles.start__contacts_icons_container}>
          <Link
            to={'https://www.facebook.com/profile.php?id=100079934987361'}
            className={styles.common_form__link}
          >
            <img
              className={styles.start__contacts_icon}
              src={facebook_icon}
              alt="linkedin_icon"
            />
          </Link>
          <Link
            to={'https://www.instagram.com/sharedelivery/'}
            className={styles.common_form__link}
          >
            <img
              className={styles.start__contacts_icon}
              src={instagram_icon}
              alt="instagram"
            />
          </Link>
          <Link
            to={'https://www.linkedin.com/showcase/sharedelivery-brand/'}
            className={styles.common_form__link}
          >
            <img
              className={styles.start__contacts_icon}
              src={linkedin_icon}
              alt="linkedin"
            />
          </Link>
          <Link
            to={'mailto://sharedelivery.net@gmail.com'}
            className={styles.common_form__link}
          >
            <img
              className={styles.start__contacts_icon}
              src={email_icon}
              alt="email"
            />
          </Link>

          <Link
            to={'https://x.com/Ekateri01703363'}
            className={styles.common_form__link}
          >
            <img
              className={styles.start__contacts_icon}
              src={whatsapp_icon}
              alt="whatsapp"
            />
          </Link>
        </div>
        <div className={styles.start__contacts_text_container}>
          <p className={styles.start__contacts_text_large}>
            Av. Cidade de Maringá 55 1piso, 2400-137 Leiria
          </p>
          <h3 className={styles.start__contacts_title}>Agreement</h3>
          <p className={styles.start__contacts_text}>All rights reserved</p>
          <p className={styles.start__contacts_text}>Picture from Freepik</p>
        </div>
      </div>
    </div>
  );
}

export default Start;
