import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styles from './footer.module.css';
import { selectUser } from '../../services/user/reducer';

import current_icon from '../../images/current_icon.svg';
import current_icon_active from '../../images/current_icon_active.svg';
import messages_icon from '../../images/messages_icon.svg';
import messages_icon_active from '../../images/messages_icon_active.svg';
import like_icon from '../../images/like_icon.svg';
import like_icon_active from '../../images/like_icon_active.svg';
import support_icon from '../../images/support_icon.svg';
import support_icon_active from '../../images/support_icon_active.svg';

import {
  CURRENT_ORDERS,
  FAVORITE_ORDERS,
  MESSAGES,
  ORDER_CREATE,
  SEARCH_ORDERS,
  SUPPORT,
} from '../../utils/constants';
import BoxIcon from '../../icons/BoxIcon';
import SearchIcon from '../../icons/SearchIcon';
import CurrentOrderIcon from '../../icons/CurrentOrderIcon';
import MessagesIcon from '../../icons/MessagesIcon';
import MessageIcon from '../../icons/MessageIcon';
import LikeIcon from '../../icons/LikeIcon';
import SupportIcon from '../../icons/SupportIcon';

function Footer() {
  const userToShow = useSelector(selectUser);
  const role = userToShow?.role;

  if (!userToShow) {
    return null;
  }

  return (
    <footer className={styles.footer}>
      {userToShow ? (
        <>
          {role === 'sender' ? (
            <NavLink
              to={ORDER_CREATE}
              className={styles.footer__icons_container}
            >
              {({ isActive }) =>
                isActive ? (
                  <>
                    <BoxIcon currentColor={'var(--light-blue)'} />
                    <h3 className={styles.footer__icon_title_active}>
                      New order
                    </h3>
                  </>
                ) : (
                  <>
                    <BoxIcon currentColor={'var(--dark-grey)'} />
                    <h3 className={styles.footer__icon_title}>New order</h3>
                  </>
                )
              }
            </NavLink>
          ) : (
            ''
          )}

          {role === 'courier' ? (
            <NavLink
              to={SEARCH_ORDERS}
              className={styles.footer__icons_container}
            >
              {({ isActive }) =>
                isActive ? (
                  <>
                    <SearchIcon currentColor={'var(--light-blue)'} />
                    <h3 className={styles.footer__icon_title_active}>Search</h3>
                  </>
                ) : (
                  <>
                    <SearchIcon currentColor={'var(--dark-grey)'} />
                    <h3 className={styles.footer__icon_title}>Search</h3>
                  </>
                )
              }
            </NavLink>
          ) : (
            ''
          )}

          <NavLink
            to={CURRENT_ORDERS}
            className={styles.footer__icons_container}
          >
            {({ isActive }) =>
              isActive ? (
                <>
                  <CurrentOrderIcon currentColor={'var(--light-blue)'} />
                  <h3 className={styles.footer__icon_title_active}>
                    Current orders
                  </h3>
                </>
              ) : (
                <>
                  <CurrentOrderIcon currentColor={'var(--dark-grey)'} />
                  <h3 className={styles.footer__icon_title}>Current orders</h3>
                </>
              )
            }
          </NavLink>

          <NavLink to={MESSAGES} className={styles.footer__icons_container}>
            {({ isActive }) =>
              isActive ? (
                <>
                  <MessageIcon currentColor={'var(--light-blue)'} />
                  <h3 className={styles.footer__icon_title_active}>Messages</h3>
                </>
              ) : (
                <>
                  <MessageIcon currentColor={'var(--dark-grey)'} />
                  <h3 className={styles.footer__icon_title}>Messages</h3>
                </>
              )
            }
          </NavLink>

          {role === 'courier' ? (
            <NavLink
              to={FAVORITE_ORDERS}
              className={styles.footer__icons_container}
            >
              {({ isActive }) =>
                isActive ? (
                  <>
                    <LikeIcon currentColor={'var(--light-blue)'} />
                    <h3 className={styles.footer__icon_title_active}>
                      Favorite orders
                    </h3>
                  </>
                ) : (
                  <>
                    <LikeIcon currentColor={'var(--dark-grey)'} />
                    <h3 className={styles.footer__icon_title}>
                      Favorite orders
                    </h3>
                  </>
                )
              }
            </NavLink>
          ) : (
            ''
          )}

          <NavLink to={SUPPORT} className={styles.footer__icons_container}>
            {({ isActive }) =>
              isActive ? (
                <>
                  <SupportIcon currentColor={'var(--light-blue)'} />
                  <h3 className={styles.footer__icon_title_active}>Support</h3>
                </>
              ) : (
                <>
                  <SupportIcon currentColor={'var(--dark-grey)'} />
                  <h3 className={styles.footer__icon_title}>Support</h3>
                </>
              )
            }
          </NavLink>
        </>
      ) : (
        ''
      )}
    </footer>
  );
}

export default Footer;
