import {
  ACCEPT,
  BASE_URL,
  LOGIN,
  LOGOUT,
  NEGOTIATION,
  NEGOTIATION_HISTORY,
  NEGOTIATIONS,
  ORDERS,
  ORDERS_SEARCH,
  REGISTER,
  SORTED_ORDERS,
  SORTED_ORDERS_AUTH,
} from './constants';

const getResponse = (res) => {
  if (!res.ok) {
    return res.json().then((error) => {
      const errorMessage = error.errors || `Error: ${res.status}`;
      return Promise.reject(new Error(errorMessage)); // Передаем объект с сообщением
    });
  }
  return res.json();
};

export const getOrderInfo = (id) => {
  return fetch(`${BASE_URL}${ORDERS}/${id}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
  }).then(getResponse);
};

export const getMessageInfo = (order_id, negotiation_id) => {
  return fetch(
    `${BASE_URL}${ORDERS}/${order_id}${NEGOTIATION_HISTORY}/${negotiation_id}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }
  ).then(getResponse);
};

export const getOrdersInfo = () => {
  return fetch(`${BASE_URL}${ORDERS_SEARCH}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
  }).then(getResponse);
};

export const getSearchedOrdersInfo = (e) => {
  return fetch(`${BASE_URL}${SORTED_ORDERS}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      from_town: e.from,
      to_town: e.to,
      from_datetime: e.date,
    }),
  }).then(getResponse);
};

export const getSearchedOrdersInfoAuth = (e) => {
  return fetch(`${BASE_URL}${SORTED_ORDERS_AUTH}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
    body: JSON.stringify({
      from_town: e.from,
      to_town: e.to,
      from_datetime: e.date,
    }),
  }).then(getResponse);
};

export const getUserOrdersInfo = (e) => {
  return fetch(`${BASE_URL}${ORDERS}/${e}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
  }).then(getResponse);
};

export const getUserMessagesInfo = (e) => {
  return fetch(`${BASE_URL}${NEGOTIATIONS}/${e}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
  }).then(getResponse);
};

export const getSenderOrdersInfo = () => {
  return fetch(`${BASE_URL}${ORDERS}/sender`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
  }).then(getResponse);
};

export const takeNewOrder = (id) => {
  return fetch(`${BASE_URL}${ORDERS}/${id}${ACCEPT}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
    body: JSON.stringify({
      action: 'accept',
    }),
  }).then(getResponse);
};

export const declineNewOrder = (id) => {
  return fetch(`${BASE_URL}${ORDERS}/${id}${ACCEPT}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
    body: JSON.stringify({
      action: 'decline',
    }),
  }).then(getResponse);
};

export const offerNewPrice = (e, id) => {
  return fetch(`${BASE_URL}${ORDERS}/${id}${NEGOTIATION}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
    body: JSON.stringify({
      price: e.price,
    }),
  }).then(getResponse);
};

export const createNewOrder = async (e, fromData, toData) => {
  return fetch(`${BASE_URL}${ORDERS}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
    body: JSON.stringify({
      from_datetime: e.from_date,
      to_datetime: e.to_date,

      from_town: fromData?.context?.place
        ? fromData.context.place.name
        : e.from_address,
      from_street: fromData?.context?.street
        ? fromData?.context?.street?.name
        : '-',
      from_house: fromData?.context?.address
        ? fromData?.context?.address?.name
        : '-',
      from_zip: fromData?.context?.postcode
        ? fromData?.context?.postcode?.name
        : '-',
      to_town: toData?.context?.place
        ? toData?.context?.place?.name
        : e.to_address,
      to_street: toData?.context?.street ? toData?.context?.street?.name : '-',
      to_house: toData?.context?.address ? toData?.context?.address?.name : '-',
      to_zip: toData?.context?.postcode?.name
        ? toData?.context?.postcode?.name
        : '-',
      to_country: 'Portugal',
      price: e.price,
      short_description: e.short_description,
      detailed_description: e.full_description,
      photos:
        '[https://avatars.mds.yandex.net/i?id=0fe9b4e3f2bb3fb9f8e545615f66cb1a191fdb9c-12831946-images-thumbs&n=13]',
      order_type: e.transport,
    }),
  }).then(getResponse);
};

export const signup = (e) => {
  return fetch(`${BASE_URL}${REGISTER}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      first_name: e.name,
      middle_name: e.second_name,
      last_name: e.surname,
      phone: `+351${e.phone}`,
      email: e.email,
      password: e.password,
      role: e.role,
    }),
  }).then(getResponse);
};

export const login = (e) => {
  return fetch(`${BASE_URL}${LOGIN}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: e.email,
      password: e.password,
    }),
  }).then(getResponse);
};

export const logout = (e) => {
  return fetch(`${BASE_URL}${LOGOUT}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('refreshToken')}`,
    },
  }).then(getResponse);
};

export const getUserInfo = () => {
  return fetch(`${BASE_URL}/auth`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
  }).then((res) => {
    return res.ok
      ? res.json()
      : fetchWithRefresh(`${BASE_URL}/token/refresh`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('refreshToken')}`,
          },
        });
  });
};

const checkReponse = (res) => {
  return res.ok ? res.json() : res.json().then((err) => Promise.reject(err));
};

export const refreshToken = () => {
  return fetch(`${BASE_URL}/token/refresh`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('refreshToken')}`,
    },
  });
};

export const fetchWithRefresh = async (url, options) => {
  try {
    const res = await fetch(url, options);
    return await checkReponse(res).then((res) => {
      if (res) {
        localStorage.setItem('accessToken', res.accessToken);
        localStorage.setItem('refreshToken', res.refreshToken);
        return res.user;
      }
    });
  } catch (err) {
    if (err.message === 'jwt expired') {
      const refreshData = await refreshToken(); //обновляем токен
      if (!refreshData.success) {
        return Promise.reject(refreshData);
      }
      localStorage.setItem('accessToken', refreshData.payload.accessToken);
      localStorage.setItem('refreshToken', refreshData.payload.refreshToken);
      options.headers.authorization = refreshData.payload.accessToken;
      const res = await fetch(url, options); //повторяем запрос
      return await checkReponse(res);
    } else {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      return Promise.reject(err);
    }
  }
};
