import { createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../utils/api';

interface LoadMessagePayload {
  order_id: string;
  negotiation_id: string;
}

export const loadMessage = createAsyncThunk(
  'message/loadMessage',
  async ({ order_id, negotiation_id }: LoadMessagePayload) => {
    return api.getMessageInfo(order_id, negotiation_id);
  }
);
