import { Checkbox, Form, Input, Radio, Select } from 'antd';
import styles from './CustomInput.module.css';

function CustomInput({ label, name, required }) {
  return (
    <div className={styles.input__container}>
      <h4 className={styles.input__title}>{label}</h4>
      <Form.Item
        name={name}
        rules={[
          {
            required: required,
            message: 'use only lower and uppercase letters',
          },
        ]}
      >
        <Input
          placeholder={`Enter your ${label} ${
            required === false ? '(optional)' : ''
          }`}
          size="large"
        />
      </Form.Item>
    </div>
  );
}

export default CustomInput;
