const SupportIcon = ({ currentColor }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0689 13.8077C7.40516 13.8077 3.61072 17.6024 3.61072 22.2669C3.61072 22.4649 3.77119 22.6257 3.96945 22.6257H20.1684C20.3665 22.6257 20.5272 22.4649 20.5272 22.2669C20.5272 17.6024 16.733 13.8077 12.0689 13.8077ZM4.33632 21.9082C4.52429 17.8052 7.92078 14.5252 12.0689 14.5252C16.2171 14.5252 19.6138 17.8052 19.8016 21.9082H4.33632Z"
        fill={currentColor}
        stroke={currentColor}
        strokeWidth="0.5"
      />
      <path
        d="M11.9282 3.19971C9.24292 3.19971 7.05823 5.3844 7.05823 8.06964C7.05823 10.7549 9.24292 12.9396 11.9282 12.9396C14.6136 12.9396 16.7981 10.7549 16.7981 8.06964C16.7981 5.3844 14.6134 3.19971 11.9282 3.19971ZM11.9282 12.2221C9.63848 12.2221 7.77569 10.3593 7.77569 8.06964C7.77569 5.77996 9.63848 3.91717 11.9282 3.91717C14.2178 3.91717 16.0806 5.77996 16.0806 8.06964C16.0806 10.3593 14.2178 12.2221 11.9282 12.2221Z"
        fill={currentColor}
        stroke={currentColor}
        strokeWidth="0.5"
      />
      <path
        d="M6.01188 10.7355C6.21014 10.7355 6.37062 10.575 6.37062 10.3768V5.73427C6.37062 5.71131 6.36798 5.68907 6.36392 5.66731C7.32509 3.43861 9.48586 2.00727 11.9284 2.00727C14.4146 2.00727 16.6115 3.49051 17.5452 5.78975V10.3497C17.2443 11.0906 16.8074 11.7569 16.2418 12.3299C16.1026 12.4711 16.104 12.698 16.2451 12.8372C16.315 12.9063 16.4061 12.9408 16.4972 12.9408C16.5897 12.9408 16.6823 12.9051 16.7524 12.8341C17.355 12.2235 17.8274 11.5178 18.1617 10.7357H18.4138C19.8915 10.7357 21.0938 9.5335 21.0938 8.05576C21.0938 6.57801 19.8915 5.37578 18.4138 5.37578H18.1464C17.0695 2.88784 14.6557 1.29004 11.9284 1.29004C9.19959 1.29004 6.78555 2.8876 5.70911 5.37578H5.502C4.02426 5.37578 2.82202 6.57801 2.82202 8.05576C2.82202 9.5335 4.02426 10.7357 5.502 10.7357H6.01188V10.7355ZM20.3763 8.05552C20.3763 9.1377 19.4957 10.018 18.4138 10.018H18.2626V6.093H18.4138C19.496 6.093 20.3763 6.97334 20.3763 8.05552ZM3.53949 8.05552C3.53949 6.97334 4.41982 6.093 5.502 6.093H5.65315V10.018H5.502C4.41982 10.018 3.53949 9.1377 3.53949 8.05552Z"
        fill={currentColor}
        stroke={currentColor}
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default SupportIcon;
