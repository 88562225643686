// export const BASE_URL: string = "https://g123124.bounceme.net/api";
export const BASE_URL: string = 'https://share-delivery.com/api';

// for backend country restriction
export const COUNTRY: string = 'pt';

// map-box urls
export const REACT_APP_ACCESS_TOKEN: string =
  'pk.eyJ1IjoidGltb29vb29uIiwiYSI6ImNtMHd3NWF4bTA2dmkya3M2cWNtbm9idjMifQ.Eh8zJn8B37xx3krH0CaMbA';

// for backend endpoints
export const ORDERS: string = '/orders';
export const USERS: string = '/users';
export const ORDER_SEARCH_LIST: string = '/order_search_list';
export const ORDERS_SEARCH: string = '/orders_search';
export const SORTED_ORDERS: string = '/sorted_orders';
export const SORTED_ORDERS_AUTH: string = '/sorted_orders_auth';
export const REGISTER: string = '/register';
export const NEGOTIATION: string = '/negotiation';
export const NEGOTIATIONS: string = '/negotiations';
export const NEGOTIATION_HISTORY: string = '/negotiation-history';
export const NUMBER: string = '/:number';

// for translation at landing
export const FROM: string = 'From';
export const TO: string = 'To';
export const SENDER: string = 'Sender';

// for front-end urls
export const SEARCH_COMMON: string = '/search-common';
export const SEARCH_RESULTS: string = '/search-results';
export const SEARCH_RESULTS_AUTH: string = '/search-results-auth';
export const SEARCH_ORDERS: string = '/search-orders';
export const FAVORITE_ORDERS: string = '/favorite-orders';
export const CURRENT_ORDERS: string = '/current-orders';
export const COMPLETED_ORDERS: string = '/completed-orders';
export const ORDER_CREATE: string = '/order-create';
export const SUPPORT: string = '/support';
export const PAYMENT_DETAILS: string = '/payment-details';
export const SETTINGS: string = '/settings';
export const TERMS_CONDITIONS: string = '/terms-conditions';
export const DELETE_ACCOUNT: string = '/delete-account';
export const MESSAGES: string = '/messages';
export const LOGIN: string = '/login';
export const LOGOUT: string = '/logout';
export const START: string = '/';
export const SIGNUP: string = '/signup';
export const FORGOT_PASSWORD: string = '/forgot-password';
export const PROFILE: string = '/profile';
export const ORDER_PHOTO: string = 'order-photo';
export const ACCEPT: string = '/accept';
export const EDIT_ORDER: string = '/edit-order';
